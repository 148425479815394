import type { IPicture } from '../../../types/pictures'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import {
  GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_NEWSLETTER,
  SUBSCRIBED_NEWSLETTER_PARAM,
} from '../../../constants/gigya'
import { PictureElementList } from '../../atoms/PictureElementList'
import { Button } from '../../atoms/Button'
import { MailOpen } from '../../atoms/Icon/Navigation/MailOpen'
import { Mail } from '../../atoms/Icon/Navigation/Mail'
import { BackgroundImage } from '../../atoms/BackgroundImage'
import { Badge } from '../../atoms/Badge'
import { setupOriginUrlCookie } from '../../../helpers/cookies'
import { ValidationNewsletterModal } from '../../molecules/ValidationNewsletterModal'
import { ConfirmModal } from '../../molecules/ConfirmModal'
import { useUser } from '../../../hook/useUser'
import { useCappingCookie } from './useCappingCookie'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { mixins, theme } from '../../../styles'
import { SELF_PROMOTION_CLICK, SELF_PROMOTION_IMPRESSION } from '../../../constants/tms'
import { SVGIcon } from '../../atoms/SVGIcon'

export interface IPromo {
  background: IPicture
  id: string
  name: string
  newsletterLink: string
  pictures: IPicture
  promoLink: string
  promoLinkTarget?: string
  absolutePromoLink: string
  type: 'newsletter' | 'promo'
  capping?: number
  wordings: {
    badge: string
    title: string
    titleNewsletter: string
    description: string
    subscribeButton: string
    subscribedButton: string
    promoButton: string
    viewExample: string
    modal?: {
      validationTitle: string
      subscribeConfirmationTitle: string
      unsubscribeConfirmationTitle: string
      confirmLabel: string
      cancelLabel: string
      closeLabel: string
    }
  }
  tms: {
    onsitead_advertiser?: string
    onsitead_category?: string
    onsitead_campaign?: string
    onsitead_creation?: string
    onsitead_format?: string
    onsitead_url?: string
    onsitead_general_placement?: string
  }
}

const NO_MODAL = 0
const VALIDATION_MODAL = 1
const CONFIRM_SUBSCRIBE_MODAL = 2
const CONFIRM_UNSUBSCRIBE_MODAL = 3

type ModalState =
  | typeof NO_MODAL
  | typeof VALIDATION_MODAL
  | typeof CONFIRM_SUBSCRIBE_MODAL
  | typeof CONFIRM_UNSUBSCRIBE_MODAL

export function Promo({
  background,
  id,
  name,
  newsletterLink,
  pictures,
  promoLink,
  promoLinkTarget,
  absolutePromoLink,
  type,
  capping,
  wordings,
  tms,
}: IPromo) {
  const {
    badge,
    title,
    titleNewsletter,
    description,
    subscribeButton,
    subscribedButton,
    promoButton,
    viewExample,
  } = wordings
  const {
    validationTitle,
    subscribeConfirmationTitle,
    unsubscribeConfirmationTitle,
    confirmLabel,
    cancelLabel,
    closeLabel,
  } = wordings?.modal || {}

  const { isLoggedIn, updateUser, userProfile, loading: isUserLoading } = useUser()
  const { cookie: cappingCookie } = useCappingCookie({ name, capping })
  const asPath = usePathname()
  const searchParams = useSearchParams()
  const { hit, isTagCommanderReady } = useContext(TagCommanderContext)
  const [subscribeStep, setSubscribeStep] = useState<ModalState>(0)
  const [confirmModalTitle, setConfirmModalTitle] = useState<string>(subscribeConfirmationTitle)

  const isNewsletter: boolean = type === 'newsletter'
  const isSubscribed = !!userProfile?.preferences?.[id]?.isConsentGranted
  const buttonLabel: string = isSubscribed ? subscribedButton : subscribeButton
  const isValidationModalOpen: boolean = subscribeStep === VALIDATION_MODAL
  const isConfirmationModalOpen: boolean =
    subscribeStep === CONFIRM_SUBSCRIBE_MODAL || subscribeStep === CONFIRM_UNSUBSCRIBE_MODAL
  const newsletterConnectLink = `/compte/accueil/?serviceType=${GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_NEWSLETTER}&${SUBSCRIBED_NEWSLETTER_PARAM}=${id}`

  const isUserAlreadySubscribed = useMemo(
    () => !!userProfile?.preferences?.[id]?.isConsentGranted,
    [isUserLoading],
  )

  function hitSelfPromotionClick(link?: string) {
    hit(
      {
        id: SELF_PROMOTION_CLICK,
        ...tms,
        onsitead_url: link || '',
      },
      {
        isClickEvent: true,
      },
    )
  }

  const toggleNewsletter = useCallback(
    function updateNewsletterMemoized(): void {
      updateUser({
        preferences: { [id]: { isConsentGranted: !isSubscribed } },
      })
    },
    [id, updateUser, isSubscribed],
  )

  const handleValidationConfirm = useCallback(
    function handleValidationConfirmMemoized(): void {
      toggleNewsletter()
      setSubscribeStep(isSubscribed ? CONFIRM_UNSUBSCRIBE_MODAL : CONFIRM_SUBSCRIBE_MODAL)
    },
    [toggleNewsletter],
  )

  const closeModal = useCallback(function closeModalMemoized(): void {
    setSubscribeStep(NO_MODAL)
  }, [])

  const handleOnClick = useCallback(
    function handleNewsletterChangeMemoized() {
      hitSelfPromotionClick()
      setConfirmModalTitle(isSubscribed ? unsubscribeConfirmationTitle : subscribeConfirmationTitle)
      setSubscribeStep(isSubscribed ? VALIDATION_MODAL : CONFIRM_SUBSCRIBE_MODAL)
      if (!isSubscribed) toggleNewsletter()
    },
    [isSubscribed],
  )

  const handleUnloggedNewsletterClick = () => {
    hitSelfPromotionClick(newsletterConnectLink)
    setupOriginUrlCookie(asPath)
  }

  // Use 2 useEffect for impression event because in newsletter we need to listen to 2 properties
  // If we do this in the same useeffect, there is a possibility to hit 2 display
  useEffect(() => {
    if (
      !cappingCookie &&
      isNewsletter &&
      !userProfile?.preferences?.[id]?.isConsentGranted &&
      isTagCommanderReady &&
      !isUserLoading
    ) {
      hit({
        id: SELF_PROMOTION_IMPRESSION,
        ...tms,
      })
    }
  }, [isTagCommanderReady, isUserLoading])

  useEffect(() => {
    if (!cappingCookie && !isNewsletter && isTagCommanderReady) {
      hit({
        id: SELF_PROMOTION_IMPRESSION,
        ...tms,
      })
    }
  }, [isTagCommanderReady])

  useEffect(() => {
    if (searchParams.get(SUBSCRIBED_NEWSLETTER_PARAM) === id) {
      setSubscribeStep(CONFIRM_SUBSCRIBE_MODAL)
    }
  }, [])

  return (
    <>
      {
        // If the promo is promo type, lets display it without user loading and subscription check
        // For newsletter type, we need to check if the user is already subscribed
        (isNewsletter && isUserAlreadySubscribed) ||
        // Display promo only if no capping cookie is present
        cappingCookie ||
        isUserLoading ? null : (
          <div className="Promo flex flex-column justify-center items-center">
            <BackgroundImage pictures={background} />
            <div className="Promo__Header flex flex-column justify-center items-center">
              <div className="Promo__Title flex flex-column">
                {badge ? (
                  <div className="Promo__Badge flex justify-center">
                    <Badge>{badge}</Badge>
                  </div>
                ) : null}
                {title ? <h3 dangerouslySetInnerHTML={{ __html: title }} /> : null}
                {description ? <p>{description}</p> : null}
              </div>
              {pictures ? (
                <div className="Promo__Image">
                  <PictureElementList {...pictures} />
                </div>
              ) : null}
            </div>
            <div className="Promo__Button">
              {isNewsletter ? (
                <Button
                  backgroundColor={
                    isSubscribed ? theme.cssVars.lightBlue : theme.cssVars.yellowGolden
                  }
                  textColor={theme.cssVars.deepBlue}
                  onClick={isLoggedIn ? handleOnClick : handleUnloggedNewsletterClick}
                  href={isLoggedIn ? undefined : newsletterConnectLink}
                >
                  {buttonLabel}
                  {!isLoggedIn ? (
                    <SVGIcon name="arrow" />
                  ) : isSubscribed ? (
                    <MailOpen
                      color={theme.cssVars.deepBlue}
                      secondary={theme.cssVars.yellowGolden}
                    />
                  ) : (
                    <Mail color={theme.cssVars.deepBlue} secondary={theme.cssVars.white} />
                  )}
                </Button>
              ) : (
                <Button
                  onClick={() => hitSelfPromotionClick(absolutePromoLink)}
                  backgroundColor={theme.cssVars.deepBlue}
                  textColor={theme.cssVars.white}
                  href={promoLink}
                  target={promoLinkTarget}
                >
                  {promoButton}
                  <SVGIcon name="arrow" primaryColor={theme.cssVars.white} />
                </Button>
              )}
            </div>
            {newsletterLink && viewExample ? (
              <a
                onClick={() => hitSelfPromotionClick(newsletterLink)}
                href={newsletterLink}
                className="Promo__Preview"
                target="_blank"
                rel="noreferrer"
              >
                {viewExample}
              </a>
            ) : null}
          </div>
        )
      }
      {isLoggedIn && (
        <>
          <ValidationNewsletterModal
            isOpen={isValidationModalOpen}
            title={validationTitle?.replace('{newsletterName}', titleNewsletter)}
            accept={confirmLabel}
            refuse={cancelLabel}
            onAccept={handleValidationConfirm}
            onRefuse={closeModal}
          />
          <ConfirmModal
            title={confirmModalTitle?.replace('{newsletterName}', titleNewsletter)}
            isOpen={isConfirmationModalOpen}
            buttonLabel={closeLabel}
            onClose={closeModal}
          />
        </>
      )}

      <style jsx>{`
        .Promo {
          position: relative;
          overflow: hidden;
          margin: 0 30px 50px;
          padding: 30px;
        }

        .Promo :global(.BackgroundImage__Filter) {
          display: block;
          opacity: 0.9;
          background-color: ${theme.cssVars.white};
        }

        .Promo__Header {
          width: 100%;
          margin-bottom: 30px;
        }

        .Promo__Title {
          width: 100%;
          margin-bottom: 30px;
          color: ${theme.cssVars.deepBlue};
        }

        .Promo__Badge {
          margin-bottom: 30px;
        }

        .Promo__Title > h3 {
          font-weight: 100;
          font-size: 30px;
          margin: 0;
          margin-bottom: 10px;
        }

        .Promo__Title > h3 :global(strong) {
          font-weight: 800;
        }

        .Promo__Title > p {
          font-weight: 400;
          font-size: 15px;
        }

        .Promo__Image {
          height: 150px;
          width: 150px;
          min-width: 150px;
        }

        .Promo__Image :global(img) {
          height: 100%;
          width: 100%;
          border-radius: 4px;
          overflow: hidden;
        }

        .Promo__Button {
          width: 100%;
          margin-bottom: 20px;
        }

        .Promo__Preview {
          text-decoration: underline;
          color: ${theme.cssVars.deepBlue};
          font-weight: 700;
          font-size: 13px;
        }

        .Promo :global(.Button > svg) {
          margin-left: 10px;
          margin-top: -2px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .Promo__Header {
            flex-direction: row;
            flex-wrap: no-wrap;
          }

          .Promo__Badge {
            justify-content: flex-start;
          }

          .Promo__Header > h3 {
            font-size: 36px;
          }

          .Promo__Header > p {
            font-size: 14px;
          }

          .Promo__Image {
            height: 160px;
            width: 160px;
            min-width: 160px;
            margin-left: 60px;
          }
        }
      `}</style>
    </>
  )
}
