import { useEffect, useRef } from 'react'
import slugify from 'slugify'
import { eraseCookie, getCookie, setCookie } from '../../../helpers/cookies'

export function useCappingCookie({ name, capping }) {
  const nameCookie = name ? slugify(name, { lower: true }) : null
  const cookieRef = useRef(nameCookie ? getCookie(nameCookie) : null)

  useEffect(() => {
    if (!name) return
    // SET cookie if have
    // capping && (need change capping value cookie || cookie is expiredAt || create firstime the cookie)
    if (capping && (cookieRef?.current !== String(capping) || !cookieRef?.current)) {
      const expireAt = new Date()
      expireAt.setHours(expireAt.getHours() + capping)

      setCookie({
        name: nameCookie,
        value: String(capping),
        expireAt: expireAt,
      })
    }

    if (!capping && cookieRef?.current) {
      eraseCookie(nameCookie)
    }
  }, [])

  return { cookie: cookieRef.current }
}
